import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import cn from 'classnames';
import { get as _get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { logOut } from '../../store/action/loginForm/login';

// import CollegeDropDown from '../../components/CollegeDropDown';
import { getOptionsAsObject } from '../../utils/LocalStorage';

//import { ReactComponent as Logo } from "../../svg_icons/clevered_green.png";
import Logo from '../../svg_icons/clevered_green.png';
import LogoWhite from '../../svg_icons/clevered_white.png';
import './Header.scss';

const isModuleSection = (path) => {
  return path.includes('lesson');
};
const Header = ({ logout, headerColor, currentModule }) => {
  const { timezone = 'Asia/Calcutta', first_name, last_name, userID, collegeLogo = false } = getOptionsAsObject('userProfile');
  const history = useHistory(),
    location = useLocation(),
    isModuleWindow = isModuleSection(location.pathname);

  const onLogout = () => {
    logout(userID);
    localStorage.clear();
    window.location.replace('/login');
  };

  const { lessonTitle = '', moduleTitle = '' } = currentModule;
  const user = getOptionsAsObject('userProfile');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      className={cn('Header', {
        'Header-Exercise': headerColor === 'green',
      })}
      //style={headerColor === "green" ? { position: "static" } : {}}
    >
      <Link className="Header-LogoWrapper" to="/dashboard">
        <img
          src={collegeLogo ? collegeLogo : isModuleWindow ? LogoWhite : Logo}
          // src={isModuleWindow ? LogoWhite : Logo}
          className="Header-Logo"
          href="/dashboard"
        />
        {/* <Logo className="Header-Logo" href="/dashboard" /> */}
      </Link>
      {/* <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#"><i class="fas fa-bars"></i></button> */}
      {location.pathname == '/dashboard' ? null : <div className="header--text">{!isModuleWindow && `Welcome, ${first_name ? first_name : 'User'} ${last_name ? last_name : ''}`}</div>}

      {/* <div
        className='Names'
        style={{ paddingRight: '40%' }}
      >
        <div className='Contain'>
          <span className='Name-Lesson'>{lessonTitle}</span>
          <span className='Name-Module'>{moduleTitle}</span>
        </div>
      </div> */}

      <div className="right">
        {/* <CollegeDropDown /> */}
        <span className="header--text">{!isModuleWindow && `Time Zone: ${timezone}`}</span>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M6.03531778,18.739764 C7.62329979,20.146176 9.71193925,21 12,21 C14.2880608,21 16.3767002,20.146176 17.9646822,18.739764 C17.6719994,17.687349 15.5693823,17 12,17 C8.43061774,17 6.32800065,17.687349 6.03531778,18.739764 Z M4.60050358,17.1246475 C5.72595131,15.638064 8.37060189,15 12,15 C15.6293981,15 18.2740487,15.638064 19.3994964,17.1246475 C20.4086179,15.6703183 21,13.9042215 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,13.9042215 3.59138213,15.6703183 4.60050358,17.1246475 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M8,10 C8,7.75575936 9.57909957,6 12,6 C14.4141948,6 16,7.92157821 16,10.2 C16,13.479614 14.2180861,15 12,15 C9.76086382,15 8,13.4273743 8,10 Z M10,10 C10,12.2692568 10.8182108,13 12,13 C13.1777063,13 14,12.2983927 14,10.2 C14,8.95041736 13.2156568,8 12,8 C10.7337387,8 10,8.81582479 10,10 Z"
              />
            </svg>
            <svg width="30px" height="30px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.18179 6.18181C4.35753 6.00608 4.64245 6.00608 4.81819 6.18181L7.49999 8.86362L10.1818 6.18181C10.3575 6.00608 10.6424 6.00608 10.8182 6.18181C10.9939 6.35755 10.9939 6.64247 10.8182 6.81821L7.81819 9.81821C7.73379 9.9026 7.61934 9.95001 7.49999 9.95001C7.38064 9.95001 7.26618 9.9026 7.18179 9.81821L4.18179 6.81821C4.00605 6.64247 4.00605 6.35755 4.18179 6.18181Z"
                fill="#000000"
              />
            </svg>
          </div>
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            {' '}
            <a className="dropdown-item" href="/profile/view">
              My Profile
            </a>
          </MenuItem>

          {user.role == 3 && (
            <MenuItem onClick={handleClose}>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FRONT_END_DOMAIN}/profile/${user.username || ''}`}>
                View Public Profile
              </a>
            </MenuItem>
          )}

          <MenuItem onClick={handleClose}>
            <span className="dropdown-item has-icon" onClick={onLogout}>
              Logout
            </span>
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
};

function mapStateToProps(state, props) {
  return {
    currentModule: _get(state, 'module.currentModule', {}),
    headerColor: _get(state, 'course.headerColor', 'white'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logOut,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
